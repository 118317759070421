import { SharedModule } from 'json-dynamic-form';
import { PinColumnComponent } from './../directives/pin-column/pin-column.component';
import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTableModule } from '@angular/material/table'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMultiSortModule } from 'ngx-mat-multi-sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '@core/constants/app-constants';

const MATERIAL_MODULES = [
    MatSidenavModule,
    MatListModule,
    MatTabsModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMultiSortModule,
    MatIconModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatTreeModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule
];

@NgModule({
    declarations: [
        PinColumnComponent
    ],
    imports: [
        CommonModule,
        PlatformModule,
        ...MATERIAL_MODULES,
        SharedModule
    ],
    exports: [
        ...MATERIAL_MODULES,
        PlatformModule,
        PinColumnComponent,
        SharedModule
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
        {provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
      ]
    
})
export class AngularMaterialModule {

}
